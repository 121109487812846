@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon-list-items {
  list-style: none;
  padding-left: 0px;
}

.icon-list-items li svg path {
  fill: white;
}

.icon-list-items li span {
  margin-right: 5px;
  color: white;
}

.icon-list-items li a {
  text-decoration: none;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.banner-section {
  background-image: url('../img/new-home-banner.jpg');
  background-position: center center;
  background-repeat: repeat;
  background-size: 100% 124%;
  height: 450px;
}

.heading {
  color: #FFFFFF;
  font-family: "Inter", Sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 48.41px;
}

.sub-heading {
  color: #FFFFFF;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
}

.inner-txt {
  font-weight: 900;
  font-size: 22px;
}

.inner-txt1 {
  font-weight: 700;
  font-size: 22px;
}

.in-field {
  background-color: #EDEDED;
  color: #000;
  border-radius: 6px !important;
  width: 100% !important;
  height: 40px !important;
  border: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 10px;
}
.in-field-area {
  background-color: #EDEDED;
  color: #000;
  border-radius: 6px !important;
  width: 100% !important;
  border: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 10px;
  min-height: 76px;
}

.in-field:focus-visible {
  outline: none !important;
}

form select:focus {
  box-shadow: none !important;
}

.head-one {
  color: #C70101;
  font-family: "Inter", Sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.15px;
}
.heading-title {
  color: #C70101;
  font-family: "Inter", Sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 0px;
}
.sub-heading-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
}
.body-txt{
  background-color: transparent;
    font-family: Arial;
    font-size: 11pt;
    white-space: pre-wrap;
    font-style: inherit;
    font-weight: inherit;
}
.form-section {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 20px 20px 10px 20px;
  border-radius: 12px 12px 12px 12px;
  margin-top: -18vh;
  z-index: 99;
  background: white;
}

.submit-btn{
  background-color: #C70101 ;
  text-transform: uppercase ;
  border-radius: 6px;
  height: 37px ;
  color: #FFFFFF;
  line-height: 0 ;
  font-family: "Inter", Sans-serif;
  font-weight: 500 ;
  font-size: 14px;
  width: auto;
    min-width: 100px;
    outline: none !important;
    border: 1px solid #C70101;
}
.top-banner-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.57px;
}
.top-banner-subtitle {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
}
.top-banner{
  background-image: url('../img/tob-banner.png');
  background-position: center center;
  background-repeat: repeat;
  background-size: 100% 100%;
  height: auto;   
  padding: 25px;              
}
.faculty-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 5px;
}
.faculty-sub-title {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 13px;
  font-weight: 400;
}
.heading-col{
  color: #3C4852;
   font-family: "Inter", Sans-serif;
  font-size: 43px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.sub-heading-col{
  color: #3C4852;
font-family: "Inter", Sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 164.286% */
/* text-transform: capitalize; */
}
.small-heading{
  color: #3C4852;
 font-family: "Inter", Sans-serif;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 107.1%; /* 23.562px */
text-transform: capitalize;
}

.small-sub-heading{
  color: #3C4852;
font-family: "Inter", Sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 23px; /* 164.286% */
text-transform: capitalize;
}

.student-speak{
  background-color: #fff;
    padding: 0px 20px;
    border: 1px solid #e7eaec;
}

#full-stars-example-two .rating-group {
  display: inline-flex;
}
#full-stars-example-two .rating__icon {
  pointer-events: none;
}
#full-stars-example-two .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
#full-stars-example-two .rating__input--none {
  display: none;
}
#full-stars-example-two .rating__label {
  cursor: pointer;
  padding: 0 0.1em;
  font-size: 22px;
  margin-top: 10px;
}
#full-stars-example-two .rating__icon--star {
  color: orange;
}
#full-stars-example-two .rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}
#full-stars-example-two .rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}
#full-stars-example-two .rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}
span.swiper-pagination-bullet {
  background: #c70101;
}
.swiper-wrapper{
  height: 220px !important;
}
.swiper-pagination{
  bottom: 0 !important;
}


.heading-h2{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 43px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%; 
  text-transform: capitalize;
}
/* .text-gray{
  color: #727070;
}
.online-coach-card .card-img{
  background-image: url(../../img/blue-bgimg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.online-coach-card .card-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.coach-card-right .card-heading{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.online-coach-detail h5{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%;
  text-transform: capitalize;
}
.bg-purple{
  color: #695FA1;
  font-weight: bold;
}
.heading-h6{
  color: #3C4852;
font-family:  "Inter", Sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 111.6%;
text-transform: lowercase;
}
.cuet-coach-fee{
  color: #3C4852;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 107.1%; 
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.faq-heading{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.faq-heading img{
  width: 35px;
  height: 35px;
}
.faq-ques{
  color: #000;
  font-family:  "Inter", Sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.faq-ques span{
  font-size: 16px;
  font-weight: 400;
}
 */



 /*----------------- Cuet-CSS ------------------*/
.cuet-coach-heading img{
  width: 40px;
  height: 40px;
}
.text-gray{
  color: #3c4852;
}
.online-coach-card .card-img{
  background-image: url(../img/blue-bgimg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.online-coach-card .card-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.coach-card-right .card-heading{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.online-coach-detail h5{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.bg-purple{
  color: #695FA1;
  font-weight: bold;
}
.heading-h6{
  color: #3C4852;
font-family:  "Inter", Sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 111.6%;
text-transform: capitalize;
}
.faq-heading{
  color: #3C4852;
  font-family:  "Inter", Sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.1%;
  text-transform: capitalize;
}
.faq-heading img{
  width: 35px;
  height: 35px;
}
.faq-ques{
  color: #000;
  font-family:  "Inter", Sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 107.1%; 
  text-transform: capitalize;
}
.faq-ques span{
  font-size: 16px;
  font-weight: 400;
}
.accordion-button:focus{
  box-shadow: none !important;
}
.accordion-button:not(.collapsed){
  background-color: #fff !important;
  color: #000000;
}



.course-top-heading{
  background-image: url(../img/banner-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.live-class-detail table{
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    border-collapse: collapse;
    border: none;
    border-radius: 10px 10px 0px 0px;
  }
  .live-class-detail table tr{
    text-align: left;
  }
  .live-class-detail table tr th{
    color: #fff;
    background-color: #C70101;
    padding: 10px 15px 10px 15px;
    font-family: "Inter", Sans-serif;
      font-size: 20px;
      font-weight: 500;
  }
  .live-class-detail table tr th:first-child {
    border-radius: 10px 0px 0px 0px;
  }
  .live-class-detail table tr th:last-child {
    border-radius: 0px 10px 0px 0px;
  }
  .live-class-detail table tr td {
    padding: 20px 15px 20px 15px;
    font-family: "Inter", Sans-serif;
    font-size: 18px;
    font-weight: 600;
  }
  .courses-page .tabBody {
    box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
   
    padding: 30px 20px 50px 20px;
    border-radius: 15px;
  }
  
  .courses-page .tabHead {
    color: #232525;
    font-family: "Inter", Sans-serif;
    font-size: 22px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .courses-page .subhead {
    text-align: center;
    color: #545050;
    font-family: "Inter", Sans-serif;
    font-size: 16px;
    font-weight: 700;
  }
  
  .iit_tab tr td {
    font-size: 14px;
  }
  
  /* .courses-page .subscribebtn {
    font-family: "Inter", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    background-color: #C70101;
    border-radius: 15px 15px 15px 15px;
    color: white;
    width: auto;
    height: 44px;
    border: none;
    padding: 0px 25px;
  } */
  .subscribebtn1 {
    font-family: "Inter", Sans-serif;
    font-size: 16px;
    font-weight: 500;
    background-color: #C70101;
    border-radius: 15px 15px 15px 15px;
    color: white;
    width: auto;
    height: 44px;
    border: none;
    padding: 0px 25px;
  }
  
  .courses-page .divbtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .heading-h3{
    font-family: "Inter", Sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 43px;
  }
  .para {
    color: #000000;
    font-family: "Inter", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
}
.mg-auto{
  margin: auto;
}
.class11 tr td{
 font-size: 16px !important;
}
.subscribebtn:focus-visible{
  border: none !important;
}

 .subscribebtn1 svg path{
  fill: white;
 }
 .subscribebtn1 svg {
  fill: white;
  width: 16px;
  margin-left: 5px;
 }
 .btn-enter {
  font-family: "Inter", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  background-color: #C70101;
  border-radius: 15px 15px 15px 15px;
  color: white;
  width: auto;
  height: 35px;
  border: none;
  padding: 0px 10px;
}

.bannerImg {
  background: url('../img/banner-image.png');
  height: auto;
  overflow: hidden;
}

.course_head {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-right{
  text-align: right;
}
.height-img{
  margin-top: 20px;
    height: 310px;
}

.w-48{
  max-width: 49%;
    margin: 0 auto;
}
.w-50{
  width: 50%;
}
.box-shade{
  box-shadow: 0px 0px 14px -4px rgba(0,0,0,0.5);
  padding: 30px 30px 30px 30px;
  border-radius: 9px;
  background-color: white;
}
.height-img1{
  height: auto;
}
.cuet-head{
  color: #E77816;
  font-family: "Inter", Sans-serif;
font-size: 35px;
font-style: normal;
font-weight: 900;
line-height: 113%; 
text-transform: capitalize;
}
.cuet-sub-head{
  color: #3C4852;
  font-family: "Inter", Sans-serif;
font-size: 27px;
font-style: normal;
font-weight: 400;
line-height: 26px; 
text-transform: capitalize;
}
.cuet-sub-txt{
  color: #3C4852;
  font-family: "Inter", Sans-serif;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: 113.6%;
text-transform: capitalize;
}
.svg-img path{
  fill: white;
}
.svg-img{
  width: 20px;
  margin: auto;
}
.exploer-btn{
  border-radius: 8px;
background: #E77816;
width: auto;
font-family: "Inter", Sans-serif;
color: white;
height: 45px;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 113.6%;
text-transform: capitalize;
border: none;
}
#carouselExampleCaptions .carousel-item{
  background-image: linear-gradient(91deg, #FFE3CA 0.72%, #F5D5B8 103.28%);
  padding: 40px;
}
#carouselExampleIndicators .carousel-item{
  background-image: linear-gradient(91deg, #FFE3CA 0.72%, #F5D5B8 103.28%);
  padding: 40px;
  height: 466px;
}
.img-w{
  height: 318px;
  object-fit: contain;
}
.carousel-indicators [data-bs-target]{
  width: 10px !important;
    height: 10px !important;
    background-color: #fd7313 !important;
    border-radius: 50%;
}
/* .carousel-indicators{
  margin-bottom: -2rem !important;
} */
.para-section {
  margin-top: 10vh;
}





@media screen and (max-width: 767px){

  .heading {
    color: #FFFFFF;
    font-family: "Inter", Sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
}
.para-section {
  margin-top: 7vh !important;
}
.banner-section {
  background-size: 100% 100%;
  height: auto;
  background-image: url(../img/home-mobile-banner.jpg);
}
.form-section{
  margin-top: 0px;
}
#carouselExampleIndicators .carousel-item{
  height: 548px !important;
}
.w-48{
  width: 100% !important;
    margin: 0 auto;
}
.height-img {
  margin-top: 20px;
  height: auto !important;
}

.in-field{
  margin-bottom: 10px !important;
}
.body-txt{
  text-align: justify;
}
.swiper-slide{
  width: 100% !important;
}
.swiper-wrapper{
  height: auto !important;
}
.subscribebtn1{
  width: auto;
  height: auto !important;
}
.sub-heading {
  font-size: 17px;
}

}

@media screen and (min-width: 768px) and (max-width: 1024px) {

.video_link img{
  width: 100%;
}
.para-section {
  margin-top: 10vh !important;
}
#carouselExampleIndicators .carousel-item{
  height: 515px !important;
}
.subscribebtn1{
  width: auto;
  height: auto !important;
}


}