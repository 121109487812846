.course-top-heading {
  background-image: url(../img/IELTS\ website\ banner\ .png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-class-detail table {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-collapse: collapse;
  border: none;
  border-radius: 10px 10px 0px 0px;
}

.live-class-detail table tr {
  text-align: left;
}

.live-class-detail table tr th {
  color: #fff;
  background-color: #C70101;
  padding: 10px 15px 10px 15px;
  font-family: "Inter", Sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.live-class-detail table tr th:first-child {
  border-radius: 10px 0px 0px 0px;
}

.live-class-detail table tr th:last-child {
  border-radius: 0px 10px 0px 0px;
}

.live-class-detail table tr td {
  padding: 20px 15px 20px 15px;
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.courses-page .tabHead {
  color: #232525;
  font-family: "Inter", Sans-serif;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.iit_tab tr td {
  font-size: 14px;
}

/* .courses-page .subscribebtn {
    font-family: "Inter", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    background-color: #C70101;
    border-radius: 15px 15px 15px 15px;
    color: white;
    width: auto;
    height: 44px;
    border: none;
    padding: 0px 25px;
  } */

.courses-page .divbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .courses-page .w48{
    width: 45% !important;
  } */
/*--------------------- Digital-Marketing-Page ---------------------*/

.dg-marketing-page .video-link-img img {
  border-radius: 25px;
}

.dg-live-cls-card {
  border: 1px solid #C2BCBC;
  padding: 20px;
}

.dg-live-cls-card:hover {
  transition: 0.3s;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.dg-live-cls-center-card {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.callbtn {
  width: auto;
  color: #FFFFFF;
  background-color: #8E0A0A;
  border-color: #8E0A0A;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  background-color: #C70101;
  border-radius: 15px 15px 15px 15px;
  border: none;
  padding: 8px 20px;
}

.bottom-call-heading {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: 30px;
}

.bg-smoke-white {
  background-color: #f0f0f0;
}

/*------------------- Class-8th ---------------*/

.class-8th-page .subscribebtn {
  font-family: "Inter", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: #C70101;
  border-radius: 15px 15px 15px 15px;
  color: white;
  width: auto;
  height: fit-content;
  border: none;
  padding: 10px 25px;
}

/*------------------- Computer-Application ---------------*/

.class-8th-page {
  overflow: hidden;
}

.card-shadow {
  box-shadow: 0px 0px 14px -4px rgba(0, 0, 0, 0.5);
}

.heading-h4 {
  color: #232525;
  font-family: "Inter", Sans-serif;
  font-size: 23px;
  font-weight: 700;
}


/*------------------- foreign-language ---------------*/


/* career */
.text-red {
  color: #C70101;
}

.top-heading {
  background-image: url(../img/top-banner.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-heading-h2 {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.57px;
}

.heading-h3 {
  font-family: "Inter", Sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 43px;
}

.form-card {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin: 0px 40px 0px 40px;
  padding: 30px 40px 30px 40px;
}

.career-rightblock form input,
.career-rightblock form select {
  background-color: #ededed;
  border-radius: 6px !important;
  width: 100% !important;
  height: 40px !important;
  border: 0 !important;
}

.career-rightblock form .form-control:hover,
.career-rightblock form select:hover {
  background-color: #E6E6E6;
}

.career-rightblock form textarea {
  background-color: #ededed;
  border-radius: 6px !important;
  width: 100% !important;
  border: 0 !important;
}

.career-rightblock form label {
  color: #777771;
  font-size: 12px;
  font-family: inherit;
  font-weight: 700;
}

.career-rightblock form .form-control:focus {
  background-color: #E6E6E6;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.submit-btn {
  background-color: #C70101 !important;
  color: #FFFFFF;
  text-transform: uppercase !important;
  border-radius: 6px !important;
  height: 37px !important;
  line-height: 0 !important;
  font-family: 'Inter' !IMPORTANT;
  font-weight: 500 !important;
  font-size: 14px !IMPORTANT;
  padding: 0px 25px;
}

.submit-btn:hover {
  background-color: #C70101 !important;
  color: #FFFFFF;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .6);
}

.para {
  color: #000000;
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

/*------------------- media-Query ---------------*/

@media screen and (max-width: 767px) {
  .heading-h3 {
    font-family: "Inter", Sans-serif;
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 27px !important;
  }

  .course-top-heading {
    background-image: url(../img/IELTS\ website\ phone\ banner.png) !important;
    background-position: center;
    background-size: 100% 100%;
    min-height: 400px !important;
  }

}

@media screen and (min-width: 1920px) {

  .course-top-heading{
  background-position: top;
  background-size: 100% 100%;
  FONT-WEIGHT: 500;
  width: 100%;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  }

}